import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          CONGRATS: 'Congratulations',
          YOU_WIN_GAME: 'You finished the game — get 100 mercoins now.',
          BOTTOM_THROW_NUMBER: 'Remaining rolls',
          BOTTOM_DICES_VALUE: 'You scored',
          BOTTOM_UNCOMPLETED_TASKS: 'My tasks',

          ROLL_DICE: 'Roll the dice',
          HELP: 'Help',
          SKIP_TASK_TITLE: 'Skip this task?',
          SKIP_TASK_DESCRIPTION: 'You can skip this task for now, but not for long: the game will end on December 29',
          BTN_WANT_MONEY: 'I want monies',
          BTN_SKIP: 'Skip',
          BTN_UNDERSTAND: 'Got it',
          BTN_OK: 'OK',
          BTN_THANK_YOU: 'Thanks',
          BTN_LETS_GO: 'Let’s go',
          BTN_OKAAAAAY: 'Okaaaay',
          BTN_FINE: 'Fine',
          BTN_WOW: 'Wow!',
          BTN_SEND: 'Send',
          BTN_CHECKOUT: 'Confirm',
          BTN_ORDER_CONFIRM: 'Confirm',
          BTN_ORDER_BACK: 'Back out',
          TASK_COMPLETE: 'Task complete',
          QUIZ_COMPLETE: 'Got it!',
          TASK_COMPLETE_ERROR: 'Oops...',
          QUIZ_COMPLETE_ERROR: 'You got it wrong 🙃',
          GIFT: 'A gift for you',
          SHOP_CART_DESCRIPTION: 'Once you confirm your order, you won’t be able to change your selection. Confirm?',
          SHOP_CART_TITLE: 'Does everything look good to you?',
          SHOP_ORDER_SUCCESS_TITLE: 'Thank you for your order',
          SHOP_ORDER_SUCCESS_DESCRIPTION: 'We’ll contact you shortly.',
          MODAL_ERROR: 'Error',
        },
      },
      ru: {
        translations: {
          CONGRATS: 'Поздравляем',
          YOU_WIN_GAME: 'Вы прошли игру — получите 100 меркоинов.',
          BOTTOM_THROW_NUMBER: 'Оставшиеся броски',
          BOTTOM_DICES_VALUE: 'Выпавшая комбинация',
          BOTTOM_UNCOMPLETED_TASKS: 'Мои задания',

          ROLL_DICE: 'Бросить кубики',
          HELP: 'Help',
          SKIP_TASK_TITLE: 'Отложить задание?',
          SKIP_TASK_DESCRIPTION: 'Можно выполнить это задание позже. Но не затягивайте — игра закончится 29 декабря.',
          BTN_WANT_MONEY: 'Хочу деняк',
          BTN_SKIP: 'Отложить',
          BTN_UNDERSTAND: 'Понятно',
          BTN_OK: 'Окей',
          BTN_THANK_YOU: 'Спасибо',
          BTN_LETS_GO: 'Поехали',
          BTN_OKAAAAAY: 'Ну и ладно',
          BTN_FINE: 'Отлично',
          BTN_WOW: 'Ничего себе!',
          BTN_SEND: 'Отправить',
          BTN_CHECKOUT: 'Оформить',
          BTN_ORDER_CONFIRM: 'Оформить',
          BTN_ORDER_BACK: 'Переобуться',
          TASK_COMPLETE: 'Задание выполнено',
          QUIZ_COMPLETE: 'Верно!',
          TASK_COMPLETE_ERROR: 'Эх...',
          QUIZ_COMPLETE_ERROR: 'А вот и нет 🙃',
          GIFT: 'Подарок',
          SHOP_CART_DESCRIPTION: 'После подтверждения заказа, изменить выбор не получится. Оставляем?',
          SHOP_CART_TITLE: 'Выбор верен ваш?',
          SHOP_ORDER_SUCCESS_TITLE: 'Спасибо за заказ',
          SHOP_ORDER_SUCCESS_DESCRIPTION: 'Мы свяжемся с вами в ближайшее время.',
          MODAL_ERROR: 'Ошибка',
        },
      },
    },
    fallbackLng: 'en',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
