import React from 'react';
import { observer } from 'mobx-react';
import { gameStore } from '../../store/store';
import TypeTaskModal from '../modals-content/type-task-modal';
import Modal from './modal';
import TypeMoveForwardModal from '../modals-content/type-move-forward-modal';
import TypeMercoinPlusModal from '../modals-content/type-mercoin-plus-modal';
import TypeQuizModal from '../modals-content/type-quiz-modal';
import TypeNotifyModal from '../modals-content/type-notify-modal';
import TypeGiftModal from '../modals-content/type-gift-modal';
import TypeGameFinishModal from '../modals-content/type-game-finish-modal';
import UncompletedTasksModal from '../modals-content/uncompleted-tasks-modal';

const SwitchModals = observer(() => {
  let quest = gameStore.user.quest;
  const isRepeatVisit = Boolean(localStorage.getItem('repeatVisit'));
  const gameFinish = gameStore.user.status.position >= 100;

  if (gameFinish && gameStore.modal.isShown) {
    return <Modal><TypeGameFinishModal/></Modal>;
  }

  if (gameStore.modal.isUnCompletedShown) {
    return (
      <Modal isTask={true} withSwiper={true}>
        <UncompletedTasksModal/>
      </Modal>
    );
  }

  if (
    !quest
    || !gameStore.modal.isShown
    || gameStore.modal.isConfirm
    || quest.isRepeatPosition
    || !isRepeatVisit
  ) {
    return null;
  }


  if (gameStore.user.status.hasAnswer) {
    return <Modal><TypeNotifyModal/></Modal>;
  }

  if (quest.type && quest.message) {
    return <Modal isTask={false}><TypeNotifyModal/></Modal>;
  }

  switch (quest.type) {
    case 'Task': {
      return <Modal><TypeTaskModal/></Modal>;
    }

    case 'MoveForward':
    case 'MoveBack': {
      return <Modal><TypeMoveForwardModal/></Modal>;
    }

    case 'MercoinPlus':
    case 'MercoinMinus': {
      return <Modal><TypeMercoinPlusModal/></Modal>;
    }

    case 'Quiz': {
      return <Modal><TypeQuizModal/></Modal>;
    }

    case 'Gift': {
      return <Modal><TypeGiftModal/></Modal>;
    }

    default: {
      return <Modal><TypeNotifyModal/></Modal>;
    }
  }
});

export default SwitchModals;
