import React from 'react';
import './dices.scss';

interface DicesProps {
  isSpin: boolean;
  dicesValue: number[] | null;
}

interface DiceProps {
  name: 'one' | 'second';
  isSpin: boolean;
  value: number;
}

const Dices: React.FC<DicesProps> = ({isSpin, dicesValue}) => {

  const Dice: React.FC<DiceProps> = ({name, isSpin, value}) => {
    const diceValues = (value: number) => {
      switch (value) {
        case 1:
          return 'show-top';
        case 2:
          return 'show-left';
        case 3:
          return 'show-front';
        case 4:
          return 'show-back';
        case 5:
          return 'show-right';
        case 6:
          return 'show-bottom';
      }
    };

    return (
      <div className={`dice dice_${name} ${isSpin ? 'spin' : ''} ${diceValues(value)} `}>
        <div className="dice__face face-front">
        </div>
        <div className="dice__face face-back">
        </div>
        <div className="dice__face face-right">
        </div>
        <div className="dice__face face-left">
        </div>
        <div className="dice__face face-top">
        </div>
        <div className="dice__face face-bottom">
        </div>
      </div>
    );
  };

  if (!dicesValue) {
    dicesValue = [4, 5];
  }

  return (
    <div className="dices">
      <div className="dices__display">
        <Dice name={'one'} isSpin={isSpin} value={dicesValue[0]}/>
      </div>
      <div className="dices__display">
        <Dice name={'second'} isSpin={isSpin} value={dicesValue[1]}/>
      </div>
    </div>
  );
};

export default Dices;
