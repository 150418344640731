import React, { useRef } from 'react';
import { gameStore } from '../../store/store';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import './uncompleted-tasks-modal.scss';
import { observer } from 'mobx-react';
import useRenderUnCompletedQuest from './useRenderUnCompletedQuest';

const UncompletedTasksModal = observer(() => {
  SwiperCore.use([Navigation]);
  const uncompletedTasks = gameStore.user.status.uncompletedQuests;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { renderUnCompletedQuest } = useRenderUnCompletedQuest();

  return (
    <div className="uncompleted-tasks-modal">
      {uncompletedTasks && uncompletedTasks.length > 1 &&
        <>
          <span className="swiper-button-next" ref={navigationNextRef}>next</span>
          <span className="swiper-button-prev" ref={navigationPrevRef}>prev</span>
        </>
      }

      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        spaceBetween={60}
        slidesPerView={1}
        allowTouchMove={false}
        onSwiper={(swiper) => {
          setTimeout(() => {
            // @ts-ignore
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            // @ts-ignore
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
      >
        {uncompletedTasks && uncompletedTasks.map((quest) => {
          return (
            <SwiperSlide key={quest.id}>
              {renderUnCompletedQuest({ quest })}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
});

export default UncompletedTasksModal;
