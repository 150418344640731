import React from 'react';
import Button from '../forms/button';

const ShopModal: React.FC<{ setShowShopModal: (v: boolean) => void }> = ({ setShowShopModal }) => {
  return (
    <>
      <h2>ЗАКРЫТО НА УЧЁТ</h2>
      <p>Надеемся, что вернёмся в 2022 году.</p>

      <div className="modal__actions">
        <Button onClickHandle={() => setShowShopModal(false)} mod={'basic'} text={'Хорошо'}/>
      </div>
    </>
  );
};

export default ShopModal;
