import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { NotFoundPage } from './pages/not-found';
import { observer } from 'mobx-react';
import { gameStore } from './store/store';
import { gameAPI } from './api/api';
import { useAuthorization } from './useAuthorization';
import './i18n';
import { useTranslation } from 'react-i18next';

export const App: React.FC = observer(() => {
  const isAuthDone = useAuthorization();

  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(gameStore.app.language).then(() => {
      localStorage.setItem('language', gameStore.app.language);
    });
  }, [gameStore.app.language]);

  useEffect(() => {
    if (!isAuthDone) {
      return;
    }

    gameStore.appIsLoading();
    gameAPI.getProfile(i18n.language).then(({ data }) => {
      gameStore.setUser(data);
      gameStore.appIsLoaded();

      if (gameStore.user.status.position >= 100) {
        gameStore.setGameOver(true);
      }
    });
    gameAPI.getUsers().then(({ data }) => {
      gameStore.setUsers(data);
    });
  }, [isAuthDone]);

  if (!isAuthDone) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/not-found" element={<NotFoundPage/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  );
});
