import MotionPathPlugin from "gsap/MotionPathPlugin";

export function anchorsToProgress(rawPath: any, resolution: any) {
  resolution = ~~resolution || 12;
  if (!Array.isArray(rawPath)) {
    rawPath = MotionPathPlugin.getRawPath(rawPath);
  }
  MotionPathPlugin.cacheRawPathMeasurements(rawPath, resolution);
  let progress = [0],
    length,
    s,
    i,
    e,
    segment,
    samples;
  for (s = 0; s < rawPath.length; s++) {
    segment = rawPath[s];
    samples = segment.samples;
    e = segment.length - 6;
    for (i = 0; i < e; i += 6) {
      length = samples[(i / 6 + 1) * resolution - 1];
      progress.push(length / rawPath.totalLength);
    }
  }
  return progress;
}
