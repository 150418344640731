import axios from 'axios';
import { IProfile, IResponseProducts, IRoll, IUsersPosition } from './types';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  window.location.reload();
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // localStorage.removeItem("accessToken");
  // localStorage.removeItem("refreshToken");
  // localStorage.removeItem("expirationTime");
  //
  // window.location.reload();
  return Promise.reject(error);
});

export const gameAPI = {
  getProfile(lang: string) {
    return axios.get<IProfile>(`/user/me`, {
      params: {
        lang,
      },
    });
  },
  getUsers() {
    return axios.get<IUsersPosition>(`/user/all`);
  },

  logout() {
    return axios.get(`/logout`);
  },

  diceRoll(lang: string) {
    return axios.get<IRoll>(`/game/dice/roll`, { params: { lang } });
  },

  move(lang: string) {
    return axios.get(`/quest/move`, { params: { lang } });
  },

  mercoin(lang: string) {
    return axios.get(`/quest/Mercoin`, { params: { lang } });
  },

  sendQuestQuiz(v: string, x: number, lang: string) {
    return axios.post(`quest/Quiz`, { answer: v, position: x, lang });
  },

  sendQuestTask(v: string, x: number, lang: string) {
    return axios.post(`quest/Task`, { answer: v, position: x, lang });
  },

  sendQuestGift() {
    // todo Проверить гифт
    return axios.get(`quest/Gift`);
  },

  skipQuest(questType: string, lang: string) {
    return axios.get(`/quest/${questType}/skip`, { params: { lang } });
  },
  getQuestBackground(position: number) {
    return `${process.env.REACT_APP_API_URL}/images/quests/${position}.jpg`;
  },
  getProductBackground(position: number, imageType?: string) {
    return `${process.env.REACT_APP_API_URL}/images/products/${position}.jpg`;
  },

  getProducts() {
    return axios.get<IResponseProducts>(`/shop/products`);
  },

  createOrder(ids: number[]) {
    return axios.post(`/shop/orders`, { orders: ids });
  },
};
