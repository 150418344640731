import React from 'react';
import { gameStore } from '../../store/store';
import Button from '../forms/button';
import { useTranslation } from 'react-i18next';

const TypeNotifyModal = () => {
  /**
   * В модалке хранится тип квеста из выбранного отложенного задания
   * Если стоим на клетке Quiz, а из отложенных был отправлен Task, то лейблы заголовков, кнопок
   * будут расчитываться как Quiz.
   */
  const quest = gameStore.modal.quest ? gameStore.modal.quest : gameStore.user.quest;
  const questFromStore = gameStore.user.quest;

  const { t } = useTranslation();

  if (!quest) {
    return null;
  }

  /**
   * После полученных или отжатых меркоинов не показываем нотификацию.
   * + подарок
   */
  if (quest.type === 'MercoinMinus' || quest.type === 'MercoinPlus' || quest.type === 'Gift') {
    gameStore.hideModal();
    return null;
  }

  const onClickHandle = () => {
    gameStore.hideModal();
    gameStore.setQuestInModal(null);

    if (gameStore.modal.isNeedUnCompleted) {
      gameStore.showUnCompletedModal();
    }
  };

  let title = '';
  let btnText = t('BTN_OKAAAAAY');

  // if (quest.type === 'MercoinPlus') {
  //   title = Constants.BTN_WOW;
  //   btnText = Constants.BTN_FINE;
  // }
  // if (quest.type === 'MercoinMinus') {
  //   title = Constants.TASK_COMPLETE_ERROR;
  //   btnText = Constants.BTN_OKAAAAAY;
  // }

  if (quest.type === 'Task') {
    title = t('BTN_WOW');
    btnText = t('BTN_FINE');
  }

  if (quest.type === 'Quiz') {
    title = questFromStore?.isAnswerCorrect ? t('QUIZ_COMPLETE') : t('QUIZ_COMPLETE_ERROR');
    btnText = questFromStore?.isAnswerCorrect ? t('BTN_THANK_YOU') : t('BTN_OKAAAAAY');
  }

  if (quest.type === 'MoveForward') {
    title = '';
    btnText = t('BTN_FINE');
  }

  if (quest.type === 'MoveBack') {
    title = t('TASK_COMPLETE_ERROR');
    btnText = t('BTN_OKAAAAAY');
  }

  // if (quest.type === 'Gift') {
  //   title = Constants.GIFT;
  //   btnText = Constants.BTN_THANK_YOU;
  // }

  return (
    <>
      <h2>
        {title}
      </h2>
      <p>
        {quest?.message || gameStore.user.quest?.message}
      </p>

      <div className="modal__actions">
        <Button onClickHandle={onClickHandle} mod={'basic'} text={btnText}/>
      </div>
    </>
  );
};

export default TypeNotifyModal;
