import { makeAutoObservable, observable } from "mobx";
import { mutate } from "swr";
import { IProfile, IQuest, IRoll, IUsersPosition } from "../api/types";

export interface IModal {
  isShown: boolean;
  quest: IQuest | null;
  isConfirm: boolean;
  isLoading: boolean;
  isUnCompletedShown: boolean;
  isNeedUnCompleted: boolean;
  isCartShown: boolean;
  isShopNotifyShown: boolean;
  shopErrorMessage: string | null;
}

export interface IApp {
  isLoading: boolean;
  playerIsMoving: boolean;
  isDicesSpin: boolean;
  isAuth: boolean;
  gameFinish: boolean;
  madeAnyMove: boolean;
  awaitingForDicesValue: boolean;
  language: "en" | "ru";
}

class GameStore {
  initialUser = {
    quest: null,
    status: {
      lastDice: [],
      isGameBlocked: false,
      isShopBlocked: false,
      email: "",
      avatar: "",
      hasAnswer: false,
      position: -1,
      numberThrow: 0,
      uncompletedQuests: null,
    },
  };

  @observable user: IProfile = { ...this.initialUser };

  @observable usersByPositions: any = [];

  @observable modal: IModal = {
    isLoading: false,
    isShown: false,
    quest: null,
    isConfirm: false,
    isUnCompletedShown: false,
    isNeedUnCompleted: false,
    isCartShown: false,
    isShopNotifyShown: false,
    shopErrorMessage: null,
  };

  @observable app: IApp = {
    isLoading: false,
    isAuth: false,
    playerIsMoving: false,
    isDicesSpin: false,
    gameFinish: false,
    madeAnyMove: false,
    awaitingForDicesValue: false,
    language: (localStorage.getItem("language") as "en" | "ru") || "en",
  };

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user: IProfile) {
    this.user = user;
  }

  setUsers(data: IUsersPosition) {
    const allUserPositions = data.users.map(
      (arrayItem: any) => arrayItem.position
    );
    const uniqueUserPositions = new Set(allUserPositions);
    const uniqueUserPositionsArray = Array.from(uniqueUserPositions);
    const usersByPositions = uniqueUserPositionsArray.map(
      (userPosition: any) => {
        const findUsers = data.users.filter(
          (user) => user.position === userPosition
        );
        return { number: userPosition, users: findUsers };
      }
    );
    this.usersByPositions = usersByPositions;
  }

  appIsLoading() {
    this.app.isLoading = true;
  }

  appIsLoaded() {
    this.app.isLoading = false;
  }

  decrementThrowCount() {
    this.user.status.numberThrow -= 1;
  }

  async updateProfile(data: Partial<IRoll>) {
    this.user = {
      quest: {
        // Проверяем пришел ли квест, в этом случае сохраняем тип, чтобы после ответа
        // показать нотификацию.
        type: data.quest ? this.user.quest?.type : null,
        ...data.quest,
      },
      status: {
        ...this.user.status,
        ...data.status,
      },
    };
    if (data.status?.points) {
      await mutate(
        "/api/profile/me",
        (old) => ({ ...old, balance: data.status?.points }),
        false
      );
    }

    if (this.user.status.position >= 100) {
      this.setGameOver(true);
    } else {
      this.setGameOver(false);
    }
  }

  updateQuestsProfile(quests: IQuest[]) {
    this.user.status.uncompletedQuests = [...quests];
  }

  resetQuestProfile() {
    this.user.quest = null;
  }

  resetProfile() {
    this.user = { ...this.initialUser };
  }

  setQuestInModal(quest: IQuest | null) {
    this.modal.quest = quest;
  }

  updateUserPosition(v: number) {
    this.user.status.position = v;
  }

  showUnCompletedModal() {
    this.modal.isUnCompletedShown = true;
  }

  hideUnCompletedModal() {
    this.modal.isUnCompletedShown = false;
  }

  setIsNeedUnCompleted(v: boolean) {
    this.modal.isNeedUnCompleted = v;
  }

  showModal() {
    this.modal.isShown = true;
  }

  hideModal() {
    this.modal.isShown = false;
  }

  setIsCartModalShown(v: boolean) {
    this.modal.isCartShown = v;
  }

  setIsShopNotifyModalShown(v: boolean) {
    this.modal.isShopNotifyShown = v;
  }

  // setIsShopErrorModalShown(v: boolean) {
  //   this.modal.isShopErrorShown = v;
  // }

  requestModal() {
    this.modal.isLoading = true;
  }

  successModal() {
    this.modal.isLoading = false;
  }

  showConfirmModal() {
    this.modal.isConfirm = true;
  }

  hideConfirmModal(skip: boolean) {
    this.modal.isConfirm = false;
    if (!skip) {
      this.showModal();
    }
  }

  moveStart() {
    this.app.playerIsMoving = true;
  }

  moveFinish() {
    this.app.playerIsMoving = false;

    if (
      this.user.quest &&
      this.user.quest.type &&
      this.app.playerIsMoving === false &&
      !this.user.quest.isRepeatPosition
    ) {
      setTimeout(() => {
        this.showModal();
      }, 500);
    }

    if (this.user.status.position >= 100) {
      setTimeout(() => {
        this.showModal();
      }, 500);
    }
  }

  dicesSpin(v: boolean) {
    this.app.isDicesSpin = v;
  }

  setGameOver(v: boolean) {
    this.app.gameFinish = v;
  }

  setMadeMove(v: boolean) {
    this.app.madeAnyMove = v;
  }

  setAwaitingForDices(v: boolean) {
    this.app.awaitingForDicesValue = v;
  }

  switchLanguage() {
    if (this.app.language === "en") {
      this.app.language = "ru";
    } else {
      this.app.language = "en";
    }

    return undefined;
  }
}

export const gameStore = new GameStore();
