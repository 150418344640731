import React, { useEffect, useMemo, useState } from 'react';
import './bottom-bar.scss';
import Dices from '../dices';
import { observer } from 'mobx-react';
import { gameStore } from '../../store/store';
import { gameAPI } from '../../api/api';
import Button from '../forms/button';
import Lottie from 'react-lottie-player';
import Stars from './images/Bottom-Bar.json';

import doubleRuLabel from './double_ru.json';
import doubleEnLabel from './double_en.json';
import { useTranslation } from 'react-i18next';

const BottomBar = observer(() => {
  const [btnPressedClass, setBtnPressedClass] = useState<'pressed' | false>(false);
  const [isDoubleLabelVisible, setDoubleLabelVisible] = useState(false);
  const [dicesValue, setDicesValue] = useState<number[]>([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setDicesValue(gameStore.user.status.lastDice);
  }, []);

  const handleBtn = () => {
    if (isButtonClicked) {
      return null;
    }
    setIsButtonClicked(true);
    setBtnPressedClass('pressed');

    setTimeout(() => {
      setBtnPressedClass(false);
      gameStore.setMadeMove(true);

      setTimeout(async () => {
        gameStore.setAwaitingForDices(true);
        gameStore.dicesSpin(true);
        gameStore.decrementThrowCount();

        const { data } = await gameAPI.diceRoll(i18n.language);
        setDicesValue(data.dice);
        gameStore.dicesSpin(false);
        setIsButtonClicked(false);
        gameStore.updateProfile(data);
      }, 500);
    }, 400);

  };

  useEffect(() => {
    if (!gameStore.modal.isShown || gameStore.user.quest?.isRepeatPosition) {
      gameStore.setAwaitingForDices(false);
    }
  }, [gameStore.modal.isShown, gameStore.user.quest]);

  const dicesSummary = useMemo((): number => {
    if (!dicesValue || !(dicesValue.length > 0)) {
      return 0;
    }

    return dicesValue[0] + dicesValue[1];
  }, [dicesValue]);

  if (gameStore.app.isLoading) {
    return null;
  }

  useEffect(() => {
    if (!gameStore.app.madeAnyMove) {
      return;
    }
    if (dicesValue.length === 0) {
      return;
    }
    if (dicesValue[0] !== dicesValue[1]) {
      return;
    }

    setDoubleLabelVisible(true);
    const timeout = setTimeout(() => setDoubleLabelVisible(false), 2.5 * 1000);

    return () => {
      clearTimeout(timeout);
      setDoubleLabelVisible(false);
    };
  }, [dicesValue]);

  const showDices = gameStore.app.isDicesSpin
    || gameStore.app.playerIsMoving
    || gameStore.modal.isShown
    || gameStore.user.status.numberThrow < 1
    || gameStore.app.gameFinish
    || gameStore.user.status.isGameBlocked
    || gameStore.app.awaitingForDicesValue;

  const showUnCompletedBtn = gameStore.user.status.uncompletedQuests && gameStore.user.status.uncompletedQuests.length > 0;

  return (
    <div className="bottom-bar">
      <Lottie
        loop={true}
        animationData={Stars}
        play
      />
      <div className={`bottom-bar__dices ${showDices ? '' : 'hidden'}`}>
        <Dices isSpin={gameStore.app.isDicesSpin} dicesValue={dicesValue}/>
      </div>

      {!showDices && (
        <div className={`bottom-bar__roll-btn ${btnPressedClass} ${gameStore.app.isDicesSpin ? 'roll' : ''}`}
             onClick={handleBtn}>
          <span>{t('ROLL_DICE')}</span>
        </div>
      )}

      <div className="bottom-bar__dices-summary">
        {dicesSummary}
      </div>

      <div className={`bottom-bar__dices-summary-text ${i18n.language}`}>{t('BOTTOM_DICES_VALUE')}</div>


      <div className="bottom-bar__throw-count">
        {gameStore.user.status.numberThrow}
      </div>

      <div className={`bottom-bar__throw-count-text ${i18n.language}`}>
        {t('BOTTOM_THROW_NUMBER')}
      </div>

      {showUnCompletedBtn &&
        <div className="bottom-bar__uncompleted-tasks">
          <Button
            mod="basic"
            isResponsive={true}
            onClickHandle={() => gameStore.showUnCompletedModal()}
            text={t('BOTTOM_UNCOMPLETED_TASKS')}
          />
        </div>
      }

      {isDoubleLabelVisible && (
        <Lottie
          loop={false}
          animationData={i18n.language === 'en' ? doubleEnLabel : doubleRuLabel}
          play
          className="bottom-bar__double-label"
        />
      )}
    </div>
  );
});

export default BottomBar;
