import React from 'react';
import './help-modal.scss';
import Button from '../forms/button';
import { useTranslation } from 'react-i18next';

const HelpModalRu: React.FC<{ setShowHelpModal: (v: boolean) => void }> = ({ setShowHelpModal }) => {

  const { t } = useTranslation();
  return (
    <div className="help-modal">
      <h1>GAME RULES</h1>
      <p>
        The objective of the game is to collect as many mercoins as you can and then spend them with abandon in Mercury merchandise store.
      </p>
      <p>
        You’ll have three days to roll the dice, from December 27 through 29. Only three dice rolls are allowed in one day, plus one bonus
        attempt if you get the same number on both dice.
      </p>
      <h2>HOW TO EARN MERCOINS</h2>
      <p>
        Mercoins are awarded for each roll of the dice in the amount of the number combination on the dice. But you can earn many more
        mercoins by completing tasks that are scattered across the map.
      </p>


      <h2>HOW TO PLAY</h2>
      <ol>
        <li>
          Click or tap on the dice to roll them.
        </li>
        <li>
          A magical force will move you forward according to the score of the dice, and will credit the same number of mercoins to your
          balance.
        </li>
        <li>
          Once you’ve landed in your destination, you will see a popup with a description of your task, a quiz, and information about
          awarded/deducted points or changes in your move - yes, that can also happen.
        </li>
        <li>
          When you have completed your task, you can make another move, but no more than three moves in one day. You can always see the
          number of remaining dice rolls in the field to the left of the dice, and the score from your last dice roll is displayed in the
          field to the right.
        </li>
      </ol>

      <h2>TASKS</h2>
      <p>
        There are several types of tasks in the game: it can be a multiple choice quiz or a popup with an input field where you need to type
        in your answer or paste a link.
      </p>
      <p>
        A specially trained human team will be checking your tasks once a day. If you chose a wrong answer, or typed something wrong in an
        input form, we’ll notice that. Your points will be deducted, and you will need to do the incorrectly completed task again, but this
        time correctly. 👮‍♀️
      </p>
      <p>
        If, for some reason, you land on the same spot again, that spot will be skipped. Points will not be awarded again for the same
        achievements or tasks.
      </p>
      <p>
        You will have a choice to put off your tasks for later, but do not delay them for too long - you will only have until December 29 to
        complete your tasks.
      </p>

      <h2>STORE OPENING DATE</h2>
      <p>
        The merchandise store will open on December 29, and this time there will be no closing date! However, special discounts on all
        products will be available only until January 8.
      </p>


      <div className="help-modal__footer">
        <h3>HAVE A MAGICAL NEW YEAR ADVENTURE!</h3>
        <Button mod={'basic'} onClickHandle={() => setShowHelpModal(false)} text={t('BTN_UNDERSTAND')}/>
      </div>
    </div>
  );
};

export default HelpModalRu;
