import React from 'react';
import Button from '../forms/button';
import { gameStore } from '../../store/store';
import { gameAPI } from '../../api/api';
import Spinner from '../spinner';
import { observer } from 'mobx-react';
import QuestImage from './QuestImage';
import { useTranslation } from 'react-i18next';

const TypeMoveForwardModal = observer(() => {
  const quest = gameStore.user.quest;

  const { t, i18n } = useTranslation();

  if (!quest) {
    return null;
  }

  const onClickHandle = () => {
    gameStore.requestModal();
    gameStore.moveStart();
    gameAPI.move(i18n.language).then((r) => {
      gameStore.hideModal();
      gameStore.updateProfile(r.data);
      gameStore.successModal();
    });

  };

  const btnText = quest.type === 'MoveForward' ? t('BTN_LETS_GO') : t('BTN_OKAAAAAY');

  return (
    <>
      <QuestImage/>
      <h2>{quest.title}</h2>
      <p>{quest.description}</p>

      <div className="modal__actions">
        {gameStore.modal.isLoading &&
          <div className="modal__actions-spinner"><Spinner light={true}/></div>
        }
        <Button onClickHandle={onClickHandle} mod={'basic'} text={btnText}/>
      </div>
    </>
  );
});

export default TypeMoveForwardModal;
