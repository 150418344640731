import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import './modal.scss';
import { gameStore } from '../../store/store';
import { observer } from 'mobx-react';
import useModalInit from './useModalInit';
import useWindowSize from './useWindowSize';

interface PortalProps {
  children: any;
  isWide?: boolean;
  isTask?: boolean;
  isCart?: boolean;
  withSwiper?: boolean;
  onCloseHandle?: (v: boolean) => void;
}

const Modal: React.FC<PortalProps> = observer(({ children, isWide, isTask, onCloseHandle, withSwiper, isCart }) => {
  const mount = document.getElementById('modal-root');
  const el = useMemo(() => document.createElement('div'), []);
  const modalRef: MutableRefObject<any> = useRef(null);
  const { windowSize } = useWindowSize();
  const [modalContentHeight, setModalContentHeight] = useState(0);

  let { isTaskNotifyClass, isLoadingClass, isWideModalClass, isTaskNotify, withSwiperClass, isCartClass } = useModalInit({
    isWide,
    isTask,
    withSwiper,
    isCart
  });

  useEffect(() => {
    if (mount) {
      mount.appendChild(el);
      return () => {
        mount.removeChild(el);
      };
    }
  }, [mount, el]);

  useEffect(() => {
    if (modalRef) {
      setModalContentHeight(modalRef.current.clientHeight);
      if (windowSize.height > modalContentHeight) {
        const delta = Math.round((windowSize.height - modalContentHeight) / 2);
        modalRef.current.style.transform = `translateY(${delta}px)`;
      } else {
        modalRef.current.style.transform = `translateY(40px)`;
      }
    }
  }, [windowSize, modalContentHeight, isTaskNotify]);

  const closeHandle = () => {
    if (isWide && onCloseHandle) {
      onCloseHandle(false);
    }

    if (gameStore.modal.isCartShown) {
      gameStore.setIsCartModalShown(false);
      return null;
    }

    if (gameStore.modal.isUnCompletedShown) {
      gameStore.hideUnCompletedModal();
      gameStore.hideModal();
      return null;
    }

    if (isTaskNotify && !onCloseHandle && !gameStore.modal.isUnCompletedShown) {
      gameStore.showConfirmModal();
    }

    gameStore.hideUnCompletedModal();
  };

  const clickOutsideHandle = () => {
    if (isWide && onCloseHandle) {
      onCloseHandle(false);
    }
  };

  if (isCartClass) {
    isTaskNotifyClass = 'modal_notify';
  }

  return createPortal((
    <div className={`modal ${isTaskNotifyClass} ${isLoadingClass} ${isWideModalClass} ${withSwiperClass} ${isCartClass}`}>
      <div className="modal__content" ref={modalRef}>
        <div className="modal__body">
          {children}
        </div>
        {(isTaskNotify || isWide) && !isCart &&
        <div onClick={closeHandle} className="modal__close">
          <span>close</span>
        </div>
        }

        <div className="modal__corner modal__corner_lt"/>
        <div className="modal__corner modal__corner_rt"/>
        <div className="modal__corner modal__corner_bl"/>
        <div className="modal__corner modal__corner_br"/>
      </div>
      <div className="modal__overlay" onClick={clickOutsideHandle}/>
    </div>
  ), el);
});


export default Modal;
