import React, { useEffect, useState } from 'react';
import './top-bar.scss';
import { observer } from 'mobx-react';
import { gameStore } from '../../store/store';
import ShopModal from '../modals-content/shop-modal';
import Modal from '../modal';
import gsap from 'gsap';
import Lottie from 'react-lottie-player';
import coins from './coins.json';
import HelpModalRu from '../modals-content/help-modal-ru';
import HelpModalEn from '../modals-content/help-modal-en';
import Stars from './images/Logo.json';
import StarsLeft from './images/Topbar-L.json';
import StarsRight from './images/Topbar-R.json';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/useAuthorization';

const TopBar = observer(() => {
  const isRepeatVisit = Boolean(localStorage.getItem('repeatVisit'));

  const [showShopModal, setShowShopModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(!isRepeatVisit);
  const [visibleBalance, setVisibleBalance] = useState(-1);
  const [playCoins, setPlayCoins] = useState(false);

  const { user } = useUser();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let tween: GSAPTween | null = null;
    let timeout: NodeJS.Timeout | null = null;

    if (!user) return;

    if (visibleBalance === -1) {
      setVisibleBalance(user.balance || 0);
    } else {
      if (gameStore.modal.isShown) {
        return;
      }
      timeout = setTimeout(() => {
        const points = { value: visibleBalance };
        setPlayCoins(true);
        tween = gsap.to(points, {
          value: user.balance,
          duration: visibleBalance === -1 ? 0 : 2,
          roundProps: 'value',
          ease: 'power1.inOut',
          onUpdate: () => setVisibleBalance(points.value),
          onComplete: () => setPlayCoins(false),
        });
      }, 1000);
    }

    return () => {
      clearTimeout(timeout!);
      tween?.kill();
      setPlayCoins(false);
    };
  }, [gameStore.modal.isShown, user?.balance]);

  const email = gameStore.user.status.email.split('@')[0];

  const logoutHandle = () => {
    gameStore.resetProfile();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expirationTime");

    window.location.reload();
  };

  const onCloseHelpHandle = () => {
    gameStore.hideModal();
    localStorage.setItem('repeatVisit', 'true');
    setShowHelpModal(false);
  };

  return (
    <div className="top-bar">
      <div className="top-bar__logo top-bar__logo_game">
        <Lottie loop={true} animationData={Stars} play/>
      </div>

      {!gameStore.app.isLoading && (
        <>
          <div className="top-bar__profile profile">
            <Lottie loop={true} animationData={StarsLeft} play/>
            <span className="profile__logout" onClick={logoutHandle}>
              Выйти
            </span>
            <span className="profile__name">{email}</span>
          </div>

          <div className="top-bar__info">
            <Lottie loop={true} animationData={StarsRight} play/>
            {playCoins && (
              <Lottie
                loop={false}
                animationData={coins}
                play
                className="coins"
              />
            )}
            <a
              href="https://store.mercdev.com"
              target="_blank"
              rel="noreferrer"
            >
              <div className="top-bar__market-button">
                <span className="top-bar__balance">{visibleBalance}</span>
              </div>
            </a>
            <span
              className="top-bar__help-button"
              onClick={() => setShowHelpModal(true)}
            >
              <span>{t('HELP')}</span>
            </span>

            <span className={`top-bar__lang-button top-bar__lang-button_${gameStore.app.language}`}
                  onClick={() => gameStore.switchLanguage()}
            ><span>{gameStore.app.language}</span>
            </span>
          </div>
        </>
      )}

      {showShopModal && (
        <Modal isCart={true}>
          <ShopModal setShowShopModal={setShowShopModal}/>
        </Modal>
      )}

      {showHelpModal && (
        <Modal isWide={true} onCloseHandle={onCloseHelpHandle}>
          {i18n.language === 'ru' && <HelpModalRu setShowHelpModal={onCloseHelpHandle}/>}
          {i18n.language === 'en' && <HelpModalEn setShowHelpModal={onCloseHelpHandle}/>}
        </Modal>
      )}
    </div>
  );
});

export default TopBar;
