import React from 'react';
import { Link } from "react-router-dom";

export const NotFoundPage = () => {

    return (
        <div>
            <p>404. Страница не найдена.</p>
            <Link to='/'>Вернуться домой</Link>
        </div>
    )
};