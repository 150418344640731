import { gameStore } from '../../store/store';
import React from 'react';

const useModalInit = (props: { isWide?: boolean, isTask?: boolean, points?: number, withSwiper?: boolean, isCart?: boolean }) => {
  const questType = gameStore.user.quest?.type;
  const isConfirmModal = gameStore.modal.isConfirm;
  const questHasAnswer = gameStore.user.status.hasAnswer;

  const isTaskNotify = (
    ((questType === 'Task' || questType === 'Quiz') && !isConfirmModal && !questHasAnswer && !gameStore.user.quest?.message)
    || props.isTask
  );

  const isTaskNotifyClass = isTaskNotify ? 'modal_task' : 'modal_notify';
  const isWideModalClass = props?.isWide ? 'modal_wide' : '';

  const isCartClass = props.isCart ? 'modal_cart' : '';

  const isLoading = gameStore.modal.isLoading;
  const isLoadingClass = isLoading ? 'modal_loading' : '';

  const withSwiperClass = props.withSwiper ? 'modal_with-swiper' : '';

  const taskPointsRender = (points?: number) => {
    const renderPoints = points ? points : gameStore.user.quest?.points;

    if (!renderPoints) {
      return null;
    }

    return (
      <div className="modal__task-points">
        <span>{renderPoints}</span>
      </div>
    );

  };

  const textWithUrl = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
  };

  return {
    isTaskNotify,
    isTaskNotifyClass,
    isWideModalClass,
    isLoadingClass,
    withSwiperClass,
    taskPointsRender,
    textWithUrl,
    isCartClass
  } as const;
};

export default useModalInit;
