import React from 'react';
import Modal from '../modal';
import Button from '../forms/button';
import { observer } from 'mobx-react';
import { gameStore } from '../../store/store';
import Spinner from '../spinner';
import { gameAPI } from '../../api/api';
import { useTranslation } from 'react-i18next';

const TypeConfirmModal = observer(() => {

  const questType = gameStore.user.quest ? gameStore.user.quest.type : null;

  const { t, i18n } = useTranslation();

  if (!questType) {
    return null;
  }

  const onSkipHandle = () => {
    gameStore.requestModal();
    gameAPI.skipQuest(questType, i18n.language).then(r => {
      gameStore.updateQuestsProfile(r.data.status.uncompletedQuests);
      gameStore.successModal();
      gameStore.hideModal();
      gameStore.hideConfirmModal(true);
    });
  };

  return (
    <Modal>
      <h2>{t('SKIP_TASK_TITLE')}</h2>
      <p>{t('SKIP_TASK_DESCRIPTION')}</p>

      <div className="modal__actions">
        {gameStore.modal.isLoading &&
          <div className="modal__actions-spinner"><Spinner light={true}/></div>
        }
        <Button mod="basic"
                onClickHandle={() => gameStore.hideConfirmModal(false)}
                text={t('BTN_WANT_MONEY')}/>

        <Button mod="danger"
                onClickHandle={onSkipHandle}
                text={t('BTN_SKIP')}/>
      </div>
    </Modal>
  );
});

export default TypeConfirmModal;
