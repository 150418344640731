import React, { useState } from 'react';
import { gameStore } from '../../store/store';
import Button from '../forms/button';
import { observer } from 'mobx-react';

import 'src/components/forms/quiz-options/quiz-options.scss';
import { gameAPI } from '../../api/api';
import Spinner from '../spinner';
import QuestImage from './QuestImage';
import useModalInit from '../modal/useModalInit';
import { useTranslation } from 'react-i18next';

const TypeQuizModal = observer(() => {
  const [quizAnswer, setQuizAnswer] = useState('');

  const { t, i18n } = useTranslation();

  const quest = gameStore.user.quest;

  if (!quest) {
    return null;
  }

  const onClickHandle = (e: any) => {
    e.preventDefault();
    gameStore.requestModal();
    gameAPI.sendQuestQuiz(quizAnswer, quest.id, i18n.language).then((r) => {
      gameStore.updateProfile(r.data);
      gameStore.successModal();
      gameStore.hideModal();
      gameStore.showModal();
    });
  };

  const quizOptions = quest.answerOptions.map((answer, i) => {
    if (!answer) {
      answer = 'Квиз не оформлен';
    }
    return (
      <li key={i} className="quiz-options__item">
        <label>
          <input type="radio" name="answer" value={answer} onChange={() => setQuizAnswer(String(answer))}/>
          <span>{answer}</span>
        </label>
      </li>
    );
  });

  const btnDisabled = quizAnswer === '' || quizAnswer.length > 1024;
  const formIsSending = gameStore.modal.isLoading;
  const { taskPointsRender, textWithUrl } = useModalInit({});

  return (
    <>
      {taskPointsRender()}
      <QuestImage/>
      <h2>{quest.title}</h2>
      {quest.description && <p dangerouslySetInnerHTML={{ __html: textWithUrl(quest.description) }}/>}

      <form onSubmit={onClickHandle} className={`modal__form ${formIsSending ? 'modal__form_disabled' : ''}`}>
        <ul className="quiz-options">
          {quizOptions}
        </ul>

        <div className={`modal__actions ${btnDisabled ? 'modal__actions_disabled' : ''}`}>
          {gameStore.modal.isLoading &&
            <div className="modal__actions-spinner"><Spinner/></div>
          }
          <Button onClickHandle={onClickHandle} mod={'basic'} text={t('BTN_SEND')} isDisabled={btnDisabled}/>
        </div>
      </form>
    </>
  );
});

export default TypeQuizModal;
