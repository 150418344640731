import React from 'react';
import { use100vh } from 'react-div-100vh';
import styles from 'src/styles/app.module.scss';

import TopBar from '../components/top-bar';
import BottomBar from '../components/bottom-bar';
import Map from '../components/map';
import SwitchModals from '../components/modal/switch-modals';
import { gameStore } from '../store/store';
import TypeConfirmModal from '../components/modals-content/type-confirm-modal';
import Spinner from '../components/spinner';
import { observer } from 'mobx-react';

export const Home = observer(() => {
  const vh = use100vh() || 0;

  if (gameStore.app.isLoading) {
    return (
      <div className="loading">
        <Spinner light={true} large={true}/>
      </div>
    );
  }

  return (
    <div style={{ height: vh }} className={styles.app}>
      <div className={styles.appTopBar}>
        <TopBar/>
      </div>
      <Map/>

      <div className={styles.appBottomBar}>
        <BottomBar/>
      </div>

      <SwitchModals/>

      {gameStore.modal.isConfirm &&
        <TypeConfirmModal/>
      }

    </div>
  );
});
