import avatarImg from 'src/components/map/images/Avatar_128px.jpg';
import React, { useMemo } from 'react';
import { gameStore } from 'src/store/store';

const UserAvatar = ({
                      user,
                      usersByPositionsIndex,
                      userIndex,
                      displayedFriends,
                    }: { user: any, usersByPositionsIndex: string, userIndex: number, displayedFriends: number }) => {


  const friendPoint = useMemo(() =>
    gameStore.usersByPositions.map((position: any) =>
      position.users.slice(0, displayedFriends).map((user: any) => (
        {
          top: Math.random() > 0.5 ? Math.random() * 20 + 60 : Math.random() * 20,
          left: Math.random() > 0.5 ? Math.random() * 20 + 60 : Math.random() * 20,
        }
      )),
    ), [gameStore.usersByPositions]);

  return (
    <div
      className="position-container__image-container"
      title={user.email.split('@')[0]}
      style={{
        top: friendPoint[usersByPositionsIndex][userIndex].top,
        left: friendPoint[usersByPositionsIndex][userIndex].left,
      }}
    >
      <img
        className={`position-container__user-avatar`}
        src={user.avatar}
        alt=""
      />
    </div>
  );
};

export default UserAvatar;
