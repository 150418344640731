import React from 'react';
import './button.scss';

interface ButtonProps {
  mod: 'basic' | 'danger';
  text: string;
  onClickHandle: any;
  isResponsive?: boolean;
  isDisabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ mod, isResponsive, text, onClickHandle, isDisabled }) => {

  const isResponsiveClass = isResponsive ? 'button_responsive' : '';
  const isDisableClass = isDisabled ? 'button_disabled' : '';

  return (
    <button
      onClick={onClickHandle}
      disabled={isDisabled}
      className={`button button_mod_${mod} ${isResponsiveClass} ${isDisableClass}`}>

      <span className="button__text">
        {text}
      </span>
    </button>
  );
};


export default Button;
