import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import { anchorsToProgress } from 'src/utils/svg';
import styles from 'src/styles/app.module.scss';
import './map.scss';
import { ReactComponent as SVGComponent } from 'src/path.svg';
import Player from '../player';
import gsap from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import { gameStore } from 'src/store/store';
import { observer } from 'mobx-react';
import Lottie from 'react-lottie-player';

import MapImage from './images/map.jpg';
import MapAtlanta from './images/map-atlanta.png';
import MapSaratov from './images/map-saratov.png';
import MapArm from './images/map-arm.png';
import MapMask1 from './images/mask1.png';
import MapMask6 from './images/mask6.png';

import booster5 from './boosters/Dot_5.json';
import booster12 from './boosters/Dot_12.json';
import booster17 from './boosters/Dot_17.json';
import booster26 from './boosters/Dot_26.json';
import booster30 from './boosters/Dot_30.json';
import booster33 from './boosters/Dot_33.json';
import booster40 from './boosters/Dot_40.json';
import booster41 from './boosters/Dot_41.json';
import booster49 from './boosters/Dot_49.json';
import booster54 from './boosters/Dot_54.json';
import booster55 from './boosters/Dot_55.json';
import booster56 from './boosters/Dot_56.json';
import booster60 from './boosters/Dot_60.json';
import booster64 from './boosters/Dot_64.json';
import booster81 from './boosters/Dot_81.json';
import booster99 from './boosters/Dot_99.json';
import UserAvatar from 'src/components/map/UserAvatar';

gsap.registerPlugin(MotionPathPlugin);

interface ContainerValues {
  scale: number;
  translation: { x: number; y: number };
}

const MAP_WIDTH = 5120;
const MAP_HEIGHT = 2880;
const HUCKING_GAP = 20;

const MAP_MIN_SCALE_MOBILE = 0.35;
const MAP_MAX_SCALE_MOBILE = 0.75;
const MAP_DEFAULT_SCALE_MOBILE = 0.5;
const MAP_MIN_SCALE_DESKTOP = 0.5;
const MAP_MAX_SCALE_DESKTOP = 1.5;
const MAP_DEFAULT_SCALE_DESKTOP = 1;

export const Map: React.FC = observer(() => {
  const MAP_MIN_SCALE = useMemo(
    () =>
      window.innerWidth >= 768 ? MAP_MIN_SCALE_DESKTOP : MAP_MIN_SCALE_MOBILE,
    [window.innerWidth],
  );
  const MAP_DEFAULT_SCALE = useMemo(
    () =>
      window.innerWidth >= 768
        ? MAP_DEFAULT_SCALE_DESKTOP
        : MAP_DEFAULT_SCALE_MOBILE,
    [window.innerWidth],
  );
  const MAP_MAX_SCALE = useMemo(
    () =>
      window.innerWidth >= 768 ? MAP_MAX_SCALE_DESKTOP : MAP_MAX_SCALE_MOBILE,
    [window.innerWidth],
  );

  const [mapState, setMapState] = useState<ContainerValues>({
    scale: MAP_DEFAULT_SCALE,
    translation: { x: 0, y: -20 },
  });
  const [currentCell, setCurrentCell] = useState(-1);
  const [isReady, setReady] = useState(false);

  const progress = useMemo(() => {
    if (!isReady) {
      return [];
    }
    return anchorsToProgress(MotionPathPlugin.getRawPath('#path path'), 12);
  }, [isReady]);

  const onMapChange = ({ scale, translation }: ContainerValues) => {
    setMapState({ scale, translation });
  };

  const moveTo = useCallback(
    (cell: number) => {
      if (currentCell === cell) {
        return;
      }
      if (progress.length === 0) {
        return;
      }

      const speed =
        currentCell === -1 ? 0 : Math.abs(cell - currentCell) * 0.35;

      gameStore.moveStart();
      gsap.to('#player', {
        motionPath: {
          path: '#path path',
          align: '#path path',
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
          start: progress[currentCell],
          end: progress[cell],
        },
        direction: cell > currentCell ? 1 : -1,
        ease: 'power1.inOut',
        duration: speed > 7 ? 7 : speed,
        onStart: () => {
          gameStore.moveStart();
        },
        onComplete: () => {
          gameStore.hideModal();
          gameStore.moveStart();
          const playerRef = document.querySelector('#player')!;
          const transform = window.getComputedStyle(playerRef).transform;
          const matrix = new DOMMatrixReadOnly(transform);

          const transition = mapState.translation;
          const x = matrix.m41 * -1 * mapState.scale + window.innerWidth / 2;
          const y = matrix.m42 * -1 * mapState.scale + window.innerHeight / 2;

          gsap.to(transition, {
            x,
            y,
            duration: speed === 0 ? 0 : 2,
            onUpdate: () => {
              gameStore.moveStart();
              setMapState((old) => ({
                ...old,
                translation: {
                  x: transition.x,
                  y: transition.y,
                },
              }));
            },
            onComplete: () => {
              setTimeout(() => {
                gameStore.moveFinish();
              }, 500);
            },
          });
          setCurrentCell(cell);
          setTimeout(() => {
            gameStore.moveFinish();
          }, 500);
        },
      });
    },
    [currentCell, progress, mapState],
  );

  const moveUsersTo = useCallback(
    (position: number) => {
      gsap.to(`#user-position${position}`, {
        motionPath: {
          path: '#path path',
          align: '#path path',
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
          start: progress[position],
          end: progress[position],
        },
      });
    },
    [progress],
  );

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    if (isReady) {
      moveTo(gameStore.user.status.position);
    }
  }, [isReady, progress, moveTo, gameStore.user.status.position]);

  useEffect(() => {
    if (isReady) {
      gameStore.usersByPositions &&
      gameStore.usersByPositions.forEach((position: any) => {
        moveUsersTo(position.number);
      });
    }
  }, [moveUsersTo, gameStore.usersByPositions]);

  const displayedFriends = useMemo(() => 7, []);

  return (
    <>
      <MapInteractionCSS
        minScale={MAP_MIN_SCALE}
        maxScale={MAP_MAX_SCALE}
        translationBounds={{
          xMin: window.innerWidth - MAP_WIDTH * mapState.scale,
          xMax: 0,
          yMin: window.innerHeight - MAP_HEIGHT * mapState.scale,
          yMax: -HUCKING_GAP,
        }}
        value={mapState}
        onChange={onMapChange}
      >
        {(
          [
            [1242, 676, booster5, 80],
            [2048, 547, booster12, 180, 260],
            [2592, 950, booster17, 180],
            [4317, 460, booster26, 180],
            [3645, 876, booster30, 140, 50],
            [3232, 1131, booster33, 60],
            [4371, 1514, booster40, 60],
            [4063, 1752, booster41, 140, 70],
            [2285, 1590, booster49, 60],
            [1285, 1130, booster54, 250, 70],
            [937, 1209, booster55, 60],
            [780, 1357, booster56, 70, 90],
            [1243, 1570, booster60, 240, 70],
            [1104, 1770, booster64, 80, 50],
            [2183, 1984, booster81, 80, 90],
            [4178, 2207, booster99, 220, 160],
          ] as Array<[number, number, object, number, number]>
        ).map(([x, y, animation, size = 150, height]) => (
          <Lottie
            animationData={animation}
            play
            style={{
              width: size,
              height: height || size,
              position: 'absolute',
              zIndex: 10,
              left: x,
              top: y,
            }}
          />
        ))}
        <div className={styles.gameBoard}>
          <img
            src={MapImage}
            alt=""
            className={styles.map}
            style={{ width: MAP_WIDTH, height: MAP_HEIGHT }}
          />
          <SVGComponent
            id={'path'}
            style={{ position: 'absolute', opacity: 0 }}
          />
          <Player/>
          {gameStore.usersByPositions &&
            gameStore.usersByPositions.map((position: any, usersByPositionsIndex: string) => (
              <div
                id={`user-position${position.number}`}
                className="position-container"
                key={position.number}
              >
                {position.users.slice(0, displayedFriends).map((user: any, userIndex: number) => (
                  <UserAvatar
                    key={`user-${user.email}`}
                    displayedFriends={displayedFriends}
                    user={user}
                    usersByPositionsIndex={usersByPositionsIndex}
                    userIndex={userIndex}/>
                ))}
              </div>
            ))}
        </div>
        <img src={MapAtlanta} alt="" className="map__atlanta-image"/>
        <img src={MapSaratov} alt="" className="map__saratov-image"/>
        <img src={MapArm} alt="" className="map__arm-image"/>
        <img src={MapMask1} alt="" className="map__mask-1"/>
        <img src={MapMask6} alt="" className="map__mask-6"/>
      </MapInteractionCSS>
    </>
  );
});
