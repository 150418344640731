import React, { useState } from 'react';
import { IQuest } from '../../api/types';
import { gameStore } from '../../store/store';
import { gameAPI } from '../../api/api';
import QuestImage from './QuestImage';
import TextField from '../forms/textfield';
import Spinner from '../spinner';
import Button from '../forms/button';
import useModalInit from '../modal/useModalInit';
import { useTranslation } from 'react-i18next';

const useRenderUnCompletedQuest = () => {
  const [inputValue, setInputValue] = useState('');
  const [quizAnswer, setQuizAnswer] = useState('');
  const { taskPointsRender, textWithUrl } = useModalInit({});

  const { t, i18n } = useTranslation();

  function updateAfterPostQuest(data: any) {
    gameStore.updateProfile(data);
    gameStore.successModal();
    gameStore.hideModal();

    if (data.quest.message) {
      if (data.status.uncompletedQuests.length > 0) {
        /**
         * Если есть еще отложенные задачи, помечаем, что после нотификации нужно
         * снова открыть модалку со свайпером отложенных задач
         */
        gameStore.setIsNeedUnCompleted(true);
      } else {
        gameStore.setIsNeedUnCompleted(false);
      }
      gameStore.hideUnCompletedModal();
      gameStore.showModal();
    }
  }

  const onClickHandle = (quest: any, e: any) => {
    e.preventDefault();
    gameStore.requestModal();

    if (quest.type === 'Task') {
      gameAPI.sendQuestTask(inputValue, quest.id, i18n.language).then((r) => {
        gameStore.setQuestInModal(quest);
        updateAfterPostQuest(r.data);
      });
    }

    if (quest.type === 'Quiz') {
      gameAPI.sendQuestQuiz(quizAnswer, quest.id, i18n.language).then((r) => {
        gameStore.setQuestInModal(quest);
        updateAfterPostQuest(r.data);
      });
    }
  };

  const renderUnCompletedQuest = ({ quest }: { quest: IQuest }) => {
    const btnDisabled = ((!inputValue || inputValue.length > 1024) && quest.type === 'Task') || ((!quizAnswer || quizAnswer.length > 1024) && quest.type === 'Quiz');
    const quizOptions = quest.answerOptions.map((answer, i) => {
      if (!answer) {
        answer = 'Квиз не оформлен';
      }
      return (
        <li key={i} className="quiz-options__item">
          <label>
            <input type="radio" name="answer" value={answer} onChange={() => setQuizAnswer(answer)}/>
            <span>{answer}</span>
          </label>
        </li>
      );
    });
    const formIsSending = gameStore.modal.isLoading;

    return (
      <div className="uncompleted-tasks__item" key={quest.id}>
        <QuestImage imageId={quest.id}/>
        {taskPointsRender(quest?.points)}

        <h2>{quest.title}</h2>
        {quest.description && <p dangerouslySetInnerHTML={{ __html: textWithUrl(quest.description) }}/>}

        <form
          onSubmit={(e) => onClickHandle(quest, e)}
          className={`modal__form ${formIsSending ? 'modal__form_disabled' : ''}`}>
          {quest.type === 'Task' &&
            <>
              <TextField value={inputValue} setValue={setInputValue}/>
            </>
          }

          {quest.type === 'Quiz' &&
            <>
              <ul className="quiz-options">
                {quizOptions}
              </ul>
            </>
          }

          <div className={`modal__actions ${btnDisabled ? 'modal__actions_disabled' : ''}`}>
            {gameStore.modal.isLoading &&
              <div className="modal__actions-spinner"><Spinner/></div>
            }
            <Button
              isDisabled={btnDisabled}
              onClickHandle={(e: any) => onClickHandle(quest, e)} mod={'basic'}
              text={t('BTN_SEND')}/>
          </div>
        </form>
      </div>
    );
  };

  return {
    renderUnCompletedQuest,
  } as const;
};

export default useRenderUnCompletedQuest;
