import React from 'react';
import Button from '../forms/button';
import { gameStore } from '../../store/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const TypeGameFinishModal = observer(() => {
  const onClickHandle = () => {
    gameStore.hideModal();
  };

  const { t } = useTranslation();

  return (
    <>
      <h2>{t('CONGRATS')}</h2>
      <p>{t('YOU_WIN_GAME')}</p>

      <div className={`modal__actions`}>
        <Button onClickHandle={onClickHandle} mod={'basic'} text={t('BTN_UNDERSTAND')}/>
      </div>
    </>
  );
});

export default TypeGameFinishModal;
