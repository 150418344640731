import React from 'react';
import './textfield.scss';

interface TextFieldProps {
  value: string;
  setValue: (v: string) => void;
}

const TextField: React.FC<TextFieldProps> = ({ value, setValue }) => {

  return (
    <div className="text-field">
      <input
        className="text-field__input"
        type="text"
        value={value}
        onChange={(e) => {
          e.preventDefault();
          setValue(e.target.value);
        }}
      />
    </div>
  );
};

export default TextField;
