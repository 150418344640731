import React, { useMemo, useState } from 'react';
import { gameStore } from '../../store/store';
import { gameAPI } from '../../api/api';
import Placeholder from '../modal/images/Placeholder.jpg';

const QuestImage = ({ imageId }: { imageId?: number}) => {
  // const questType = gameStore.user.quest?.type;

  const userPosition = gameStore.user.status.position;

  // const needImages = (
  //   gameStore.modal.isUnCompletedShown
  //   || questType === 'MercoinPlus'
  //   || questType === 'MercoinMinus'
  //   || questType === 'MoveForward'
  //   || questType === 'MoveBack'
  //   || questType === 'Task'
  //   || questType === 'Quiz'
  //   || questType === 'Gift');

  //&& !gameStore.modal.isConfirm
  // && !gameStore.user.status.hasAnswer;

  const [isLoadRealImage, setIsLoadRealImage] = useState(false);
  const disabledPlaceholderImageClassName = useMemo(
    () => isLoadRealImage ? 'modal__image_disabled' : '',
    [isLoadRealImage]
  );
  const loadedRealImageClassName = useMemo(
    () => isLoadRealImage ? '' : 'modal__real-image_disabled',
    [isLoadRealImage]
  );

  const loadRealImage = (e: any) => {
    setIsLoadRealImage(true);
  };

  // if (!needImages) {
  //   return null;
  // }

  return (
    <div className="modal__image-box">
      <img
        className={`modal__real-image ${loadedRealImageClassName}`}
        src={gameAPI.getQuestBackground(imageId ? imageId : userPosition)}
        onLoad={(e: any) => { loadRealImage(e) }}
        alt=""
      />
      <img
        className={`modal__image ${disabledPlaceholderImageClassName}`}
        src={Placeholder}
        alt=""
      />
    </div>
  );
};

export default QuestImage;
