import React, { useEffect } from 'react';
import './player.scss';
import { observer } from 'mobx-react';
import { gameStore } from '../../store/store';
import Lottie from 'react-lottie-player';
import gsap from 'gsap';

import playerCircle from './circle.json';

const Player: React.FC = observer(() => {
  const image = gameStore.user.status.avatar || null;

  useEffect(() => {
    gsap.to('.player__circle', {
      opacity: gameStore.app.playerIsMoving ? 0 : 1,
      duration: 1,
    });
  }, [gameStore.app.playerIsMoving])

  return (
    <div className="player" id="player">
      <div className="player__image-box">
        {image &&
        <img src={image} alt="player" className="player__image"/>
        }
      </div>
      
      <Lottie animationData={playerCircle} play className="player__circle" />
    </div>
  );
});

export default Player;
