import React from 'react';
import './help-modal.scss';
import Button from '../forms/button';
import { useTranslation } from 'react-i18next';

const HelpModalRu: React.FC<{ setShowHelpModal: (v: boolean) => void }> = ({ setShowHelpModal }) => {

  const { t } = useTranslation();
  return (
    <div className="help-modal">
      <h1>Правила игры</h1>

      <p>
        Главная цель игры — набрать максимальное количество меркоинов, чтобы потом неистово тратить их в магазине меркурианского мерча.
      </p>

      <p>
        Броски кубика начисляются с 27 по 29 декабря — по 3 броска в день, плюс один за каждый выпавший дубль.
      </p>

      <h2>Как зарабатывать меркоины?</h2>

      <p>
        Меркоины капают за каждый бросок кубика в количестве выпавшей комбинации. Но самое большое количество можно получить, выполняя
        задания, которые раскиданы на карте.
      </p>


      <h2>
        Порядок хода
      </h2>

      <ol>
        <li>Жмакайте на кубики.</li>

        <li>Неведомая сила двигает вас на то количество ходов, сколько очков выпало на кубике и зачисляет на ваш баланс такое же количество
          меркоинов.
        </li>
        <li>Попав на нужную клетку, вам откроется попап с условиями задания, квизом, уведомлением о начислении/списании баллов или об
          изменении
          порядка хода — да, такое тоже может случиться.
        </li>
        <li>Выполнив задание, вы получаете возможность сделать следующий ход — и так 3 раза в день. Количество оставшихся бросков всегда
          видно
          в поле слева от кубиков, а последняя выпавшая комбинация — в поле справа.
        </li>
      </ol>

      <h2>
        Задания
      </h2>

      <p>
        В игре несколько видов заданий: квиз с вариантами ответов, где верный только один, или попапы с формой ввода, куда нужно вписать
        текст или оставить ссылку.
      </p>

      <p>
        Специально обученная человеческая команда проверяет ваши задания раз в день. Если вы ошиблись с ответом и отправили через форму
        ввода что-то не то, то мы это заметим. В результате с вас спишутся баллы или мы заставим выполнить неверное задание еще раз, только
        нормально 👮‍♀️
      </p>

      <p>
        Если по каким-то причинам вы попали на одну и ту же клетку несколько раз — она становится проходной. Получить баллы за достижения
        или задания второй раз не получится.
      </p>

      <p>
        Есть вариант отложить задания на потом, но не затягивайте — их можно выполнить только до 29 декабря.
      </p>

      <h2>Время работы магазина</h2>
      <p>Магазин мерча откроется 29 декабря и в этот раз не планирует закрываться! Но до 8 января будут действовать специальные новогодние скидки на все позиции.</p>


      <div className="help-modal__footer">
        <h3>Волшебного вам новогоднего приключения!</h3>
        <Button mod={'basic'} onClickHandle={() => setShowHelpModal(false)} text={t('BTN_UNDERSTAND')}/>
      </div>
    </div>
  );
};

export default HelpModalRu;
