import React, { useState } from 'react';
import Button from '../forms/button';
import TextField from '../forms/textfield';
import { gameStore } from '../../store/store';
import { observer } from 'mobx-react';
import { gameAPI } from '../../api/api';
import Spinner from '../spinner';
import QuestImage from './QuestImage';
import useModalInit from '../modal/useModalInit';
import { useTranslation } from 'react-i18next';

const TypeTaskModal = observer(() => {
  const [inputValue, setInputValue] = useState('');

  const quest = gameStore.user.quest;

  const { t, i18n } = useTranslation();

  if (!quest) {
    return null;
  }

  const onClickHandle = (e: any) => {
    e.preventDefault();
    gameStore.requestModal();
    gameAPI.sendQuestTask(inputValue, quest.id, i18n.language).then((r) => {
      gameStore.updateProfile(r.data);
      gameStore.successModal();
      gameStore.hideModal();
      gameStore.showModal();
    });
  };

  const btnDisabled = inputValue === '' || inputValue.length > 1024;
  const formIsSending = gameStore.modal.isLoading;

  const { taskPointsRender, textWithUrl } = useModalInit({});

  return (
    <>
      {taskPointsRender()}
      <QuestImage/>

      <h2>{quest.title}</h2>
      {quest.description && <p dangerouslySetInnerHTML={{ __html: textWithUrl(quest.description) }}/>}

      <form onSubmit={onClickHandle} className={`modal__form ${formIsSending ? 'modal__form_disabled' : ''}`}>
        <TextField value={inputValue} setValue={setInputValue}/>

        <div className={`modal__actions ${btnDisabled ? 'modal__actions_disabled' : ''}`}>
          {gameStore.modal.isLoading &&
            <div className="modal__actions-spinner"><Spinner/></div>
          }
          <Button onClickHandle={onClickHandle} mod={'basic'} text={t('BTN_SEND')} isDisabled={btnDisabled}/>
        </div>
      </form>
    </>
  );
});

export default TypeTaskModal;
