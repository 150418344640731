import React from 'react';
import './spinner.scss';

const Spinner: React.FC<{ light?: boolean, large?: boolean }> = ({ light, large }) => {
  return (
    <div className={`spinner ${light ? 'spinner_light' : ''} ${large ? 'spinner_large' : ''}`}>

    </div>
  );
};

export default Spinner;
