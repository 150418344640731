import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";
import useSWR from "swr";

interface IAccountRefreshData {
  accessToken: string;
  accessTokenType: string;
  accessTokenExpiresIn: number;
  issureAt: string;
  refreshToken: string;
  refreshTokenExpiresIn: number;
}

const hubUrl = process.env.REACT_APP_HUB_URL;
const clientUrl = process.env.REACT_APP_CLIENT_URL;

if (!hubUrl) throw new Error("Please, set up REACT_APP_HUB_URL first");
if (!clientUrl) throw new Error("Please, set up REACT_APP_CLIENT_URL first");

const setIntervalImmediate = (cb: () => void, interval: number) => {
  cb();
  return setInterval(cb, interval);
};

const refreshTokens = async (refreshToken: string) => {
  console.log("Refreshing. Time is ", Date.now());
  const hubApiUrl = process.env.REACT_APP_HUB_API_URL;
  if (!hubApiUrl) throw new Error("Please, set up REACT_APP_HUB_API_URL first");
  
  try {
    const response = await axios.post<IAccountRefreshData>(
      `/account/refresh`,
      {
        token: refreshToken,
      },
      { baseURL: hubApiUrl }
    );
    
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      window.location.href = `${hubUrl}/?redirectUri=${clientUrl}&providers=google`;
    }
    
    return Promise.reject(error);
  }
};

export const useAuthorization = () => {
  const [queryAccessToken, setQueryAccessToken] = useQueryParam(
    "accessToken",
    StringParam
  );
  const [queryRefreshToken, setQueryRefreshToken] = useQueryParam(
    "refreshToken",
    StringParam
  );
  const [queryExpiresIn, setQueryExpiresIn] = useQueryParam(
    "expiresIn",
    NumberParam
  );
  
  const [accessToken, setAccessToken] = useState("");
  const isAuthDone = useMemo(() => Boolean(accessToken), [accessToken]);
  
  const [queryTokensSet, setQueryTokensSet] = useState(false);
  
  useEffect(() => {
    if (!queryAccessToken || !queryRefreshToken || !queryExpiresIn) {
      setQueryTokensSet(true);
      return;
    }
    
    console.log("Query useEffect");
    
    localStorage.setItem("accessToken", queryAccessToken);
    localStorage.setItem("refreshToken", queryRefreshToken);
    
    const expirationTime = Date.now() + queryExpiresIn * 1000;
    localStorage.setItem("expirationTime", String(expirationTime));
    
    console.log("Set tokens from query, refresh is: ", queryRefreshToken);
    
    setQueryAccessToken(undefined);
    setQueryRefreshToken(undefined);
    setQueryExpiresIn(undefined);
    
    setQueryTokensSet(true);
    
    const url = document.location.href;
    window.history.pushState({}, "", url.split("?")[0]);
    
  }, [
    queryAccessToken,
    queryExpiresIn,
    queryRefreshToken,
    setQueryAccessToken,
    setQueryExpiresIn,
    setQueryRefreshToken,
  ]);
  
  useEffect(() => {
    if (!queryTokensSet) return;
    
    console.log("Tokens useEffect");
    
    let localAccessToken = localStorage.getItem("accessToken");
    let localRefreshToken = localStorage.getItem("refreshToken");
    let localExpirationTime = parseInt(
      localStorage.getItem("expirationTime") || String(Date.now())
    );
    let isRefreshingInProgress = false;
    
    if (!localAccessToken || !localRefreshToken) {
      return void (window.location.href = `${hubUrl}/?redirectUri=${clientUrl}&providers=google`);
    }
    
    if (localExpirationTime > Date.now() + 50 * 1000) {
      console.log("Time not expired, setting access token");
      axios.defaults.headers.common.authorization = `Bearer ${localAccessToken}`;
      setAccessToken(localAccessToken);
    }
    
    console.log("Got tokens from storage, refresh is ", localRefreshToken);
    
    const interval = setIntervalImmediate(async () => {
      console.log("Interval");
      
      if (!localExpirationTime || !localRefreshToken) {
        console.error("No tokens");
        return;
      }
      
      if (localExpirationTime <= Date.now() + 1000 * 50) {
        try {
          if (isRefreshingInProgress) return;
          console.log(
            "Time expired, setting new tokens with ",
            localRefreshToken
          );
          isRefreshingInProgress = true;
          console.log("refreshing in progress: ", isRefreshingInProgress);
          
          const {
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
            accessTokenExpiresIn,
          } = await refreshTokens(localRefreshToken);
          
          console.log("Got new refresh: ", newRefreshToken);
          
          localStorage.setItem("accessToken", newAccessToken);
          localAccessToken = newAccessToken;
          setAccessToken(newAccessToken);
          axios.defaults.headers.common.authorization = `Bearer ${newAccessToken}`;
          
          localRefreshToken = newRefreshToken;
          localStorage.setItem("refreshToken", newRefreshToken);
          
          const newExpirationTime = Date.now() + accessTokenExpiresIn * 1000;
          localExpirationTime = newExpirationTime;
          localStorage.setItem("expirationTime", String(newExpirationTime));
          
          isRefreshingInProgress = false;
          console.log(
            `Обновили токен. След. обновление через ${accessTokenExpiresIn - 50
            } секунд`
          );
        } catch (err) {
          console.error(err);
          
          return void (window.location.href = `${hubUrl}/?redirectUri=${clientUrl}&providers=google`);
        }
      }
    }, 1000);
    
    return () => clearInterval(interval);
  }, [queryTokensSet]);
  
  return isAuthDone;
};

interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  balance?: number;
  userPhoto: {
    thumbnail: string;
  };
}

const fetcher = async (url: string) => {
  try {
    const hubApiUrl = process.env.REACT_APP_HUB_API_URL;
    if (!hubApiUrl) throw new Error("Please, set up REACT_APP_HUB_API_URL first");
    
    const { data } = await axios.get(url, { baseURL: hubApiUrl });
    return data;
  } catch (e) {
    return void (window.location.href = `${hubUrl}/?redirectUri=${clientUrl}&providers=google`);
  }
};

export const useUser = () => {
  const { data, error } = useSWR<IUser>("/api/profile/me", fetcher, {
    refreshInterval: 0,
    revalidateOnFocus: false,
  });
  
  return {
    user: data,
    isUserError: error,
    isUserLoading: !data && !error,
  };
};
