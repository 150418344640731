import React from 'react';
import Button from '../forms/button';
import { gameStore } from '../../store/store';
import { observer } from 'mobx-react';
import { gameAPI } from '../../api/api';
import Spinner from '../spinner';
import QuestImage from './QuestImage';
import { useTranslation } from 'react-i18next';

const TypeGiftModal = observer(() => {
  const quest = gameStore.user.quest;

  const { t } = useTranslation();

  if (!quest) {
    return null;
  }

  const onClickHandle = () => {
    gameStore.requestModal();
    gameAPI.sendQuestGift().then((r) => {
      gameStore.updateProfile(r.data);
      gameStore.successModal();
      gameStore.hideModal();
      gameStore.showModal();
    });
  };

  return (
    <>
      <QuestImage/>
      <h2>{quest.title}</h2>
      <p>{quest.description}</p>

      <div className="modal__actions">
        {gameStore.modal.isLoading &&
          <div className="modal__actions-spinner"><Spinner light={true}/></div>
        }
        <Button onClickHandle={onClickHandle} mod={'basic'} text={t('BTN_OK')}/>
      </div>
    </>
  );
});

export default TypeGiftModal;
